import $ from 'cash-dom'

let url = null
let searchTimeout = null
let query = null
let dropdown = null
let searchResultItems = []
let lang = document.documentElement.lang

function search(e) {
  if (!e.target.value || (e.target.value && e.target.value.length < 2)) {
    cancelSearch()
    return
  }

  if (searchTimeout) {
    clearTimeout(searchTimeout)
  }
  
  searchTimeout = setTimeout(() => {
    if (query === e.target.value) {
      return 
    }

    query = e.target.value
    performSearch(query)
  }, 1000)
}

function performSearch(query) {
  fetch(`${url}?q=${query}`)
    .then((response) => response.json())
    .then((data) => {
      searchResultItems = data
      addItemsToDropdown()
    })
}

function cancelSearch() {
  if (searchTimeout) {
    clearTimeout(searchTimeout)
  }

  hideDropdown()
  searchResultItems = []
  query = null
}

function addItemsToDropdown() {
  let noResultsText = lang === 'sv' ? 'Din sökning gav inga resultat.' : 'Your search did not return any results.'

  let html = '' 

  searchResultItems.each((item) => {
    html += `<a href="${item.url}" class="search-result-item">
              <h4 class="search-result-item-heading">${item.title}</h4>
              <p class="search-result-item-meta">${item.category.title}</p>
            </a>`
  })
  
  dropdown.html(html)

  if (searchResultItems.length > 0) {
    showDropdown()
  } else {
    showDropdown()
    let html = ''
    html += `<div class="search-result-item">
              <p class="search-result-item-meta">${noResultsText}</p>
            </div>`

    dropdown.html(html)
  }
}

function showDropdown() {
  dropdown.addClass('is-active')

  setTimeout(() => {
    $(document).off('click.searchDropdown').on('click.searchDropdown', () => {
      hideDropdown()
    })
  }, 100)
}

function hideDropdown() {
  dropdown.removeClass('is-active')
  $(document).off('click.searchDropdown')
}

function init() {
  if (document.getElementById('knowledgeEntrySearchForm')) {
    url = document.getElementById('knowledgeEntrySearchForm').action
    dropdown = $('#knowledgeEntrisSearchResult')
  }
}

$(document).on('focus', '#knowledgeEntrySearchInput', (e) => {
  if (e.target.value && searchResultItems.length > 0) {
    showDropdown()
  }
})

$(document).on('search', '#knowledgeEntrySearchInput', (e) => {
  cancelSearch()
  query = e.target.value
  performSearch(query)
})

$(document).on('submit', '#knowledgeEntrySearchForm', (e) => {
  e.preventDefault()
})

$(window).on('load', init)
$(document).on('input', '#knowledgeEntrySearchInput', search)